import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppLetThemKnow = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our Future - Register to Vote"
      description="We&rsquo;llgenerate a message for you to send your friends and groups. Let's make voting go viral!"
      shareImage="revoke"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Save our Future!

Make sure everyone you know is registered to vote in the EU Elections.

The deadline is 12th April
💥 *Share* this message with your friends and groups:
➡ https://saveourfuture.uk/letthemknow ⬅

💥 *Register* to Vote:
➡ https://saveourfuture.uk/register ⬅

Let's make the European voice huge in the UK!`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppLetThemKnow.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppLetThemKnow;

export const query = graphql`
  query LetThemKnowQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
